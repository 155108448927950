

$(document).ready(function(){
	$('.datepicker').datepicker({});

	// Mobile menu top
	if ($(window).width() < 961) {
		setTimeout(function(){
			var headerHt = $('header').innerHeight();
			var menuHt = $('body').innerHeight() - headerHt;
			$('.pure-css-menu ul').css({'top': headerHt, 'min-height': menuHt});
		}, 300);
	}

	//Open modal from another modal (close 1st modal)
	$("#orderConfirmed").on('show.bs.modal', function (e) {
		$("#orderSummary").modal("hide");
	});

	//Tooltip init
	$('.btn-tooltip').tooltip();

	// Expand mobile slide menu
	$('.expand-mobile-slide-menu').on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('.mobile-slide-menu').slideToggle();
	});

	//Croppie image uploader
	var resize = $('#upload-demo').croppie({
		enableExif: true,
		enableOrientation: true,
		viewport: { // Default { width: 100, height: 100, type: 'square' }
			width: '100%',
			height: '100%',
			type: 'square' //square
		},
		boundary: {
			width: 200,
			height: 200
		}
	});


	$('#image').on('change', function () {
	  var reader = new FileReader();
		reader.onload = function (e) {
		  resize.croppie('bind',{
			url: e.target.result
		  }).then(function(){
			$('.btn-upload-image').show();
			$('a.btn.file-btn').hide();
		  });
		};
		reader.readAsDataURL(this.files[0]);
	});


	$('.btn-upload-image').on('click', function (ev) {
	  resize.croppie('result', {
		type: 'canvas',
		size: 'viewport'
	  }).then(function (img) {
		$.ajax({
		  url: "croppie.php",
		  type: "POST",
		  data: {"image":img},
		  success: function (data) {
			var html = '<img src="' + img + '" />';
			$("#preview-crop-image").html(html);
			$('#upload-demo').hide();
			$('a.btn.file-btn').hide();
			$("#preview-crop-image").show();

		  }
		});
	  });
	  $(this).hide();
	});
	//Croppie image uploader end

	//show and hide div on Radion button switch
	$('.button-view input[type="radio"]').click(function(){
        var inputValue = $(this).attr("value");
        var targetBox = $("." + inputValue);
		console.log(targetBox);
        $(".bar-chart-toggle").not(targetBox).hide();
        $(targetBox).show();
    });

	$("#home_sign_in").on('click', function (e) {
	 	e.preventDefault();
        var login_form = document.getElementById("login_form");
        login_form.style.display = "block";
        var sign_in = document.getElementById("sign_in_page");
        sign_in.style.display = "none";
    });

});



